var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.supportedCountries.includes(_vm.countryCode))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"PackageIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.countryCode.toUpperCase())+" customs")])]},proxy:true}],null,false,2469740075)},[(_vm.countryCode === 'lv')?_c('div',[_c('validation-observer',{ref:"customsValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Technical Username","label-for":"tech-username"}},[_c('validation-provider',{attrs:{"name":"Technical Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tech-username","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.lv.techUser),callback:function ($$v) {_vm.$set(_vm.formData.lv, "techUser", $$v)},expression:"formData.lv.techUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1197723115)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Technical Password","label-for":"tech-password"}},[_c('validation-provider',{attrs:{"name":"Technical Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tech-password","type":'password',"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.lv.techPassword),callback:function ($$v) {_vm.$set(_vm.formData.lv, "techPassword", $$v)},expression:"formData.lv.techPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3791539645)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"System Code","label-for":"system-code"}},[_c('validation-provider',{attrs:{"name":"System Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"system-code","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.lv.systemCode),callback:function ($$v) {_vm.$set(_vm.formData.lv, "systemCode", $$v)},expression:"formData.lv.systemCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2074814124)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"TIN","label-for":"tin"}},[_c('validation-provider',{attrs:{"name":"TIN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tin","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.lv.tin),callback:function ($$v) {_vm.$set(_vm.formData.lv, "tin", $$v)},expression:"formData.lv.tin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,992548417)})],1)],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"type":"button","variant":"primary","id":"customs-settings-submit"},on:{"click":_vm.submitForm}},[_vm._v(" Submit ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }