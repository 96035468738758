<template>
  <!-- Tab: Country settings -->
  <b-tab v-if="supportedCountries.includes(countryCode)">
    <template #title>
      <feather-icon
        icon="PackageIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">{{ countryCode.toUpperCase() }} customs</span>
    </template>
    <div v-if="countryCode === 'lv'">
      <validation-observer ref="customsValidation">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Technical Username"
              label-for="tech-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Technical Username"
                rules="required"
              >
                <b-form-input
                  id="tech-username"
                  v-model="formData.lv.techUser"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Technical Password"
              label-for="tech-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Technical Password"
                rules="required"
              >
                <b-form-input
                  id="tech-password"
                  v-model="formData.lv.techPassword"
                  :type="'password'"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="System Code"
              label-for="system-code"
            >
              <validation-provider
                #default="{ errors }"
                name="System Code"
                rules="required"
              >
                <b-form-input
                  id="system-code"
                  v-model="formData.lv.systemCode"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="TIN"
              label-for="tin"
            >
              <validation-provider
                #default="{ errors }"
                name="TIN"
                rules="required"
              >
                <b-form-input
                  id="tin"
                  v-model="formData.lv.tin"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </div>

    <!-- Submit button -->
    <b-row>
      <b-col>
        <b-button
          type="button"
          variant="primary"
          id="customs-settings-submit"
          @click="submitForm"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-tab>
</template>
<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BTab,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: ['country', 'companyId'],
  data() {
    return {
      supportedCountries: [
        'lv',
      ],
      countryCode: '',
      formData: {
        lv: {
          edsUser: '',
          edsPassword: '',
          systemCode: '',
          techUser: '',
          techPassword: '',
          tin: '',
        },
      },
    }
  },
  watch: {
    country(newValue) {
      this.countryCode = newValue.toLowerCase()
      if (this.supportedCountries.includes(this.countryCode)) {
        this.fetchData()
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.customsValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.$http.post(`/v1/companies/${this.companyId}/customs/${this.countryCode}`, this.formData[this.countryCode])
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
      })
    },
    fetchData() {
      this.$http.get(`/v1/companies/${this.companyId}/customs/${this.countryCode}`)
        .then(response => {
          switch (this.countryCode) {
            case 'lv':
              this.$data.formData.lv = response.data
              break
            default:
              break
          }
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>
